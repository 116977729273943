<template>
  
  <nav>
    <ul class="pagination">
      <li class="prev-next page-item">
        <a v-if="1 !== actual" title="Aller à la page précédente" href="#" @click.prevent="changePage(actual-1)">
          <span aria-hidden="true">«</span>
        </a>
      </li>
      
      <li class="page-item" v-bind:class="{active: 1 == actual}" v-if="last >= 3">
        <a v-if="1 !== actual" v-bind:title="lastPageTitle()" href="#" @click.prevent="changePage(1)">1</a>
        <a v-else>1</a>
      </li>  
      
      <li v-if="actual > 3" class="page-item">
        <a>…</a>
      </li>  
      
      <li v-for='n in pageRange' class="page-item" v-bind:class="{active: n == actual}">
        <a v-if="n !== actual" v-bind:title="title(n)" href="#" @click.prevent="changePage(n)">{{n}}</a>
        <a v-else>{{n}}</a>
      </li>
      
      <li v-if="actual < last - 2" class="page-item">
        <a>…</a>
      </li>  
      
      <li class="page-item" v-bind:class="{active: last == actual}" v-if="last >= 3">
        <a v-if="last !== actual" v-bind:title="lastPageTitle()" href="#" @click.prevent="changePage(last)">{{last}}</a>
        <a v-else>{{last}}</a>
      </li>
      
      <li class="prev-next page-item">
        <a v-if="last !== actual" title="Aller à la page suivante" href="#" @click.prevent="changePage(actual+1)">
          <span aria-hidden="true">»</span>
        </a>
      </li>
    </ul>
  </nav>
  
</template>

<script>
export default {
  name: 'pagination',
  props: {
    actual: Number,
    last: Number,
  },
  computed: {
    pageRange() {
      if(3 > this.last) {
        if(this.last == 1)
          return [1]
        else
          return [1,2]
      }
      else if( 3 > this.actual) {
        return [2,3]
      }
      else if(this.last < this.actual+2) {
        return [this.last-2,this.last-1]
      }
      
      return [this.actual-1,this.actual,this.actual+1]
    },
  },
  methods: {
    title(n) {
      return`Aller à la page ${n} sur ${this.last}`
    },
    changePage(page) {
      if(page !== this.actual) this.$emit("changePage",page)
    },
    firstPageTitle() {
      return`Aller à la page 1 sur ${this.last}`
    },
    lastPageTitle() {
      return`Aller à la page ${this.last} sur ${this.last}`
    }
  }
}
</script>
