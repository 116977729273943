<template>
	<div>
		<div v-if="!isMobile()" class="list-assmats" :class="mapDisplayed ? 'grid-3-small-1 grid-with-gutter' : 'grid-4-small-1 grid-with-gutter'">
			<cardAssmat v-for="a in assmats" :key="a.id" :assmat="a" showDistance></cardAssmat>
		</div>
		<div v-else class="list-assmats" :class="mapDisplayed ? 'grid-3-small-1 grid-with-gutter' : 'grid-3-small-1 grid-with-gutter'">
			<cardAssmat v-for="a in assmats" :key="a.id" :assmat="a" showDistance></cardAssmat>
		</div>
	</div>
</template>

<script>
import cardAssmat from "@/components/cardAssmat.vue";

export default {
	name: "multipleCardsAssmats",
	props: {
		assmats: Array,
		mapDisplayed: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		cardAssmat,
	},
};
</script>
