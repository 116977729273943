<template>
  <main :class="hasSelection ? 'layout-simple-standard' : 'layout-special'">
    
    <button @click="displayOverlayMap()" class="btn-cta-pill btn-floating-left" v-if="isMobile() && !overlayMapActive && !overlayFormActive"><span class="fa fa-solid fa-map-marked-alt"></span>Carte</button>
    <button @click="overlayCloseButton()" class="btn-cta-pill btn-floating-left" v-if="isMobile() && overlayMapActive && !overlayFormActive"><span class="fa fa-solid fa-map-marked-alt"></span>Liste</button>
    <button @click="displayOverlayForm()" class="btn-cta-pill btn-floating-right" v-if="isMobile() && !overlayFormActive"><span class="fa fa-solid fa-sliders-h"></span>Affiner</button>
    
    
    <header class="layer-primary" v-if="!isMobile()">
      <div class="main-img">
        <img loading="lazy" alt="Maine-et-Loire.fr" src="@/assets/assmat-recherche.jpg" />
      </div>
    </header>
    
    
    <section class="section-content" id="section-content">
      <header>
        <encartSelection></encartSelection>
        <ul id="site-nav-breadcrumbs" class="site-nav-breadcrumbs">
          <li>
            <router-link :to="{name: 'accueil'}">Accueil</router-link>
          </li>
          <li>Trouvez un assistant maternel !</li>
        </ul>
        <h1>Trouvez un assistant maternel !</h1>   
      </header>
      <div>
        <article id="main-content">
          <searchForm advanced @research="getSearch" v-if="!isMobile()"></searchForm>
          <section v-if="errored">{{errored}}</section>
          <div v-if="results.length" :class="!isMobile() && mapDisplayed ? 'grid-8-small-all grid-with-gutter' : ''">
            <div id="results" :class="!isMobile() && mapDisplayed ? 'col-5-small-all' : ''">
              <button v-if="!mapDisplayed" @click="mapDisplayButton()" class="btn-cta-light mr-0"><span class="fa fa-solid fa-map-marked-alt"></span> Afficher la carte</button>
              <h2>Résultats de la recherche</h2>
              <p class="smallItalic"><span class="fa fa-solid fa-info-circle"></span> Les résultats sont affichés du plus proche au plus éloigné par rapport à l'adresse recherchée.</p>
              <p v-if="results.length" class="results-nb">Voici les fiches <b>{{24*(actualPage-1)+1}}</b> à <b>{{24*(actualPage-1)+results.length}}</b> sur <b>{{nombreResultats}}</b> assistants maternels au total.</p>
              <multipleCardsAssmats :assmats="results" :key="cardsAssmatsKey" :mapDisplayed="mapDisplayed"></multipleCardsAssmats>
              <pagination v-if="results.length" :actual="actualPage" :last="lastPage" @changePage="changePage"></pagination>
            </div>
            
            <div id="map" class="col-3-small-all" style="position: relative;" v-if="!isMobile() && mapDisplayed">
              <mapContainer v-if="results.length" :markers="getMarkers(results)" :height="500" :key="mapKey"></mapContainer>
              <a v-if="mapDisplayed" @click="mapCloseButton()" class="btn-close-with-shadow"><span class="sr-only sr-only-focusable">Masquer la carte</span></a>
            </div>
          </div>
        </article>
      </div>
    </section>
    
    <div class="overlay" :class="{ 'js-open': overlayFormActive || overlayMapActive }" title='Cliquez dans la zone grise pour fermer cette fenêtre' >
      <div class="wrap" :class="{ 'd-flex': overlayFormActive || overlayMapActive}">
        <h2>Recherchez un assistant maternel</h2>
        <searchForm v-if="overlayFormActive" advanced @research="getSearch"></searchForm>
        <mapContainer v-if="overlayMapActive && results.length" :markers="getMarkers()" :height="500" :key="mapKey"></mapContainer>
      </div>
      <a @click="overlayCloseButton()" class="btn-close" id="overlay-close-button"><span class="sr-only sr-only-focusable">Fermer le menu</span></a>
    </div>
  </main>
</template>

<script>
import searchForm from '@/components/searchForm.vue';
import pagination from '@/components/pagination.vue';
import mapContainer from '@/components/mapContainer.vue';
import multipleCardsAssmats from '@/components/multipleCardsAssmats.vue';
import encartSelection from '@/components/encartSelection.vue';

export default {
  name: 'Recherche',
  data() {
    return {
      queryParams: {},
      results: [],
      errored: null,
      actualPage: 1,
      lastPage: null,
      mapKey: 0,
      cardsAssmatsKey: 0,
      overlayFormActive: false,
      overlayMapActive: false,
      nombreResultats: 0,
      mapDisplayed: true
    };
  },
  components: {
    searchForm,
    pagination,
    mapContainer,
    multipleCardsAssmats,
    encartSelection
  },
  computed: {
    hasSelection: function() {
      return this.$store.getters.assmats.length
    },
    pageTitle: function() {
      document.title = `Trouvez un assistant maternel ! - ${this.$route.query.search} - Page ${this.actualPage} - Assmat (49)`
    }
  },
  mounted() {
    if (typeof this.$route.query.search !== 'undefined') {
      this.getSearch();
    }
    
    this.$nextTick(() => {
      this.pageTitle()
    });
  },
  methods: {
    getSearch: function () {
      if(this.isMobile()) {
        this.overlayCloseButton()
      }
      
      this.$http
      .get('recherche', {
        params: this.$route.query
      })
      .then((result) => {
        this.actualPage = result.data.pageActuelle;
        this.lastPage = result.data.nombrePages;
        this.nombreResultats = result.data.nombreResultats;
        this.results = result.data.resultats;
        this.mapKey += 1;
        this.errored = null
        
        this.pageTitle()
      })
      .catch((error) => {
        if(error.response.status === 404) {
          this.results = []
          this.errored = "Aucun résultat disponible pour vos critères de recherche."
        }
        else {
          this.errored = "Un problème est survenu, nous ne pouvons pas faire suite à votre demande.";
        }
      });
    },
    changePage: function (page) {
      const query = Object.assign({}, this.$route.query);
      query.numPage = page;
      this.$router.push({ query });
      
      this.getSearch();
    },
    displayOverlayForm: function () {
      if (this.overlayFormActive) {
        this.overlayFormActive = false;
      } else {
        this.overlayFormActive = true;
      }
    },
    displayOverlayMap: function () {
      if (this.overlayMapActive) {
        this.overlayMapActive = false;
      } else {
        this.overlayMapActive = true;
      }
    },
    overlayCloseButton: function () {
      this.overlayFormActive = false;
      this.overlayMapActive = false;
    },
    mapCloseButton: function () {
      this.mapDisplayed = false;
    },
    mapDisplayButton: function () {
      this.mapDisplayed = true;
    }
  }
};
</script>
<style>
.mr-0 {
  margin-right: 0;
}

.smallItalic {
  font-size: smaller;
  font-style: italic;
}
</style>
